import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import ethereumLogo from '../../assets/images/ethereum-original-light.svg'

const ERC20_DEFAULT_IMG = ethereumLogo as string

const ERC20TestnetTokens: ITokenDict = {
  [NetworkCurrencyEnum.eth]: {
    "title": "Ether",
    "img": "https://etherscan.io/images/svg/brands/ethereum-original.svg",
    "rate": "$3580.00",
    "address": undefined,
    "link": "https://sepolia.etherscan.io/",
    "group": `Base Currency (${NetworkCurrencyEnum.eth})`,
    "website": "https://ethereum.org/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.eth
  },
  USDT: {
    "title": "Tether USD",
    "img": "https://etherscan.io/token/images/tethernew_32.png",
    "rate": "$1.00",
    "address": "0x7169D38820dfd117C3FA1f22a697dBA58d90BA06",
    "link": "https://sepolia.etherscan.io/token/0x7169d38820dfd117c3fa1f22a697dba58d90ba06",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 18,
    "symbol": "USDT"
  },
  SLM: {
    "title": "Salmon",
    "img": "https://etherscan.io/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0x1Bab38919E00B68107A259D48128e779FeF2442A",
    "link": "https://sepolia.etherscan.io/address/0x1Bab38919E00B68107A259D48128e779FeF2442A",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "SLM"
  },
}

export {ERC20TestnetTokens, ERC20_DEFAULT_IMG}
import {IWeb3Facade} from "../IWeb3Facade";
import {
  AddressType, BalanceDataByAddress,
  EstimateResultType,
  IAccount,
  IDataForGenerateTransactions,
  IDataForSendTransactions,
  IGeneralTxData,
  IMapValueByAddress,
  ITokenDict,
  ITransactionPriorityEnum,
  NetworkType,
  PrivateKeyType
} from "../../types";
import {MICRO_LAMPORTS_PER_LAMPORT, setProviderWeb3, Web3SolType} from "../../../../store/web3/web3Sol";
import {SPLTokens} from "../../../../store/solscan/SPLTokens";
import {JsonRpcError} from "web3-types/src/json_rpc_types";
import {Keypair, PublicKey, TransactionInstruction, TransferParams, VersionedTransaction} from "@solana/web3.js";
import {IRpcResponse} from "../../../../models/chainScan.models";
import {HexStr} from "../../../../store/web3/web3";
import {GasHelper, getPrecisionByNumber} from "../../../../helpers";
import {SPLDevnetTokens} from "../../../../store/solscan/SPLDevnetTokens";

interface IDataForGenerateSOLTransactions extends IDataForGenerateTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  privateKeyByAddress: IMapValueByAddress<IAccount['privateKey']>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: AddressType
}

export interface IDataForSendSOLTransactions extends IDataForSendTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  privateKeyByAddress: IMapValueByAddress<IAccount['privateKey']>,
  transactionDataByAddress: IMapValueByAddress<ITxSolData>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: AddressType
}

export interface ITxSolData extends IGeneralTxData {
  /** Account that will transfer lamports */
  fromPubkey: PublicKey;
  /** Account that will receive transferred lamports */
  toPubkey: PublicKey;
  /** Amount of lamports to transfer */
  lamports: number | bigint;
}

const TransactionPriorityEnum: ITransactionPriorityEnum = {
  low: "low",
  medium: "medium",
  high: "high",
  veryHigh: "veryHigh"
} as const

/**
 * not real or exactly limit
 * just for skip some rpc node
 *
 * follow numbers means count of rpc call per http request
 */
type LimitRpcHttp = 10 | 100 | 1000

const devNetProvidersRpcHttp = [
  {rpcHttp: 'https://devnet.helius-rpc.com/?api-key=4b1ac6d2-c07e-4d1a-823b-a813af0df797', limit: 10},
  {rpcHttp: 'https://devnet.helius-rpc.com/?api-key=17a8d914-f8e0-4ae3-ac51-9da6f7fda713', limit: 10},
  {rpcHttp: 'https://nd-593-186-741.p2pify.com/10ba0786b05daf5058efd4e007e60222', limit: 100},
  {
    rpcHttp: 'https://rpc.ankr.com/solana_devnet/e0d86470574f2e6a2c92028aa7c3adffe3f591aa96111d9b6027c4bea0556415',
    limit: 100
  },
  {rpcHttp: 'https://solana-devnet.g.alchemy.com/v2/5o4gmyqnmqP8g8xSuhmQWi29nzpj4p52', limit: 1000}
]

const mainNetProvidersRpcHttp = [
  {rpcHttp: 'https://api.mainnet-beta.solana.com', limit: 10},
  {rpcHttp: 'https://mainnet.helius-rpc.com/?api-key=17a8d914-f8e0-4ae3-ac51-9da6f7fda713', limit: 10},
  {rpcHttp: 'https://mainnet.helius-rpc.com/?api-key=4b1ac6d2-c07e-4d1a-823b-a813af0df797', limit: 10},

  {rpcHttp: 'https://go.getblock.io/a6471ec93ef24d27b05c559bfbec366a', limit: 100},
  {rpcHttp: 'https://go.getblock.io/0fc64b70f1854a5498d9f1f22b07aa4a', limit: 100},

  {rpcHttp: 'https://solana-mainnet.core.chainstack.com/aad8dbfb20d93b79dac0e1e8a9fa5457', limit: 100},
  {rpcHttp: 'https://solana-mainnet.core.chainstack.com/04ac989a7cfb2e24935523f4c6eb3d12', limit: 100},

  {rpcHttp: 'https://solana-mainnet.g.alchemy.com/v2/g6eeTNOE4rcXfDHcML9-w9KYm18w9k2j', limit: 1000},
  {rpcHttp: 'https://solana-mainnet.g.alchemy.com/v2/5o4gmyqnmqP8g8xSuhmQWi29nzpj4p52', limit: 1000},
]

class SOLFacade implements IWeb3Facade {
  /**
   * @see _getPriorityFeeEstimate
   * @protected
   */
  protected readonly _feeDefaultInLamports = 5_000;
  protected readonly _linkForTxScan: string;
  protected readonly _defaultTransactionPriority: keyof typeof TransactionPriorityEnum;
  protected readonly _transactionPriorityOptions: ITransactionPriorityEnum;
  protected readonly _tokensDict: ITokenDict;
  protected readonly _network: NetworkType;
  protected readonly limitPrivateKeys;
  protected readonly addressesChunkSize;
  private readonly __web3ProviderIterator;
  private readonly __web3ProviderByLink = new Map();
  private __web3ProviderPriority: LimitRpcHttp | null;

  constructor() {
    this._defaultTransactionPriority = TransactionPriorityEnum.high
    this._transactionPriorityOptions = {
      [TransactionPriorityEnum.low]: "Low",
      [TransactionPriorityEnum.medium]: "Average",
      [TransactionPriorityEnum.high]: "High",
      [TransactionPriorityEnum.veryHigh]: "Very High"
    }
    this._tokensDict = process.env.REACT_APP_ENVIRONMENT === 'dev' ? SPLDevnetTokens : SPLTokens
    this._network = 'sol'
    this._linkForTxScan = process.env.REACT_APP_LINK_FOR_TX_SOL_SCAN

    this.__web3ProviderIterator = this._getWeb3ProviderIterator()

    this.limitPrivateKeys = 990
    this.addressesChunkSize = 990
  }

  get tokensDict() {
    return this._tokensDict
  }

  get network() {
    return this._network
  }

  get linkForTxScan() {
    return this._linkForTxScan
  }

  get defaultTransactionPriority() {
    return this._defaultTransactionPriority
  }

  get transactionPriorityOptions() {
    return this._transactionPriorityOptions
  }

  protected get _web3Provider(): Web3SolType {
    return this.__web3ProviderIterator.next().value
  }

  protected set _web3ProviderLimit(limit: LimitRpcHttp | null) {
    this.__web3ProviderPriority = limit
  }

  getLimitPrivateKeys() {
    return this.limitPrivateKeys
  }

  getAddressesChunkSize() {
    return this.addressesChunkSize
  }

  * _getWeb3ProviderIterator() {
    const providersRpcHttp = process.env.REACT_APP_ENVIRONMENT === 'dev' ? devNetProvidersRpcHttp : mainNetProvidersRpcHttp

    while (true) {
      for (const {rpcHttp, limit} of providersRpcHttp) {

        if (this.__web3ProviderPriority && limit < this.__web3ProviderPriority) continue

        if (!this.__web3ProviderByLink.has(rpcHttp)) {
          this.__web3ProviderByLink.set(rpcHttp, setProviderWeb3(rpcHttp))
        }

        this._web3ProviderLimit = null

        yield this.__web3ProviderByLink.get(rpcHttp)
      }
    }
  }

  async fetchBaseCurrencyBalanceDataByAddress(addressList: Set<AddressType>): Promise<BalanceDataByAddress> {
    const batchSize = 100
    const TX_INTERVAL = 100
    const balanceByAddress: BalanceDataByAddress = new Map();

    const numTransactions = Math.ceil(addressList.size / batchSize);

    const result = []
    const addresses = Array.from(addressList)
    for (let i = 0; i < numTransactions; i++) {
      const {
        connection,
        getBalance
      } = this._web3Provider
      const addressChunk = []
      const multAcc = []
      let lowerIndex = i * batchSize;
      let upperIndex = (i + 1) * batchSize;
      for (let j = lowerIndex; j < upperIndex; j++) {
        if (!addresses[j]) break
        addressChunk.push(addresses[j])
        multAcc.push(new PublicKey(addresses[j]));
      }
      result.push((new Promise((resolve) => {
          setTimeout(() => {
            connection.getMultipleAccountsInfo(multAcc).then(values => {
              for (const i in values) {

                const address = addressChunk[i]
                const account = values[i]

                if (account?.executable === false && account?.lamports) {
                  balanceByAddress.set(address, getBalance.outputFormatter(account.lamports))
                }
              }
              resolve(true)
            });
          }, i * TX_INTERVAL);
        })
      ));
    }

    await Promise.allSettled(result)

    return balanceByAddress
  }

  async _getPriorityFeeEstimate(txInstruction: TransactionInstruction, sender: Keypair, priorityLevel: keyof ITransactionPriorityEnum): Promise<{
    totalFee: bigint,
    computeLimitIx: TransactionInstruction,
    computePriceIx?: TransactionInstruction
  }> {
    type SimResp = {
      "accounts": any | null,
      "err": any | null,
      "innerInstructions": any | null,
      "logs": string[],
      "returnData": any | null,
      "unitsConsumed": number
    }
    /**
     * Lamports per signature: 5000
     * Base Transaction Fee already set, which is 5000 lamports per signature in your transaction
     * @inheritDoc https://solana.com/developers/guides/advanced/how-to-use-priority-fees#what-are-priority-fees
     */
    const FeeDefault = this._feeDefaultInLamports

    /**
     * A transfer SOL transaction takes 300 CU
     * @inheritDoc https://solana.com/developers/guides/advanced/how-to-use-priority-fees#how-do-i-implement-priority-fees
     */
    const ComputeUnitsDefault = 200_000 as const
    const PriorityFeeDefault = BigInt(0) as const //for priorityFeeLevel [min]

    const {
      getLatestBlockhash,
      getMinimumBalanceForRentExemption,
      getAccountInfo,
      getPriorityFeeEstimate,
      ComputeBudgetProgram,
      simulateTransaction,
      connection,
      BatchTransaction
    } = this._web3Provider


    let computeLimitIx = ComputeBudgetProgram.setComputeUnitLimit({
      units: ComputeUnitsDefault
    });


    let priorityFee: bigint = PriorityFeeDefault
    let unitLimit: number = ComputeUnitsDefault
    if (priorityLevel !== TransactionPriorityEnum.low) {
      const recentHash = await getLatestBlockhash("finalized")
      const _tx = new BatchTransaction({
        recentBlockhash: recentHash.blockhash
      })
      _tx.add(
        computeLimitIx,
        txInstruction
      )
      _tx.sign(sender)
      const {
        error,
        result
      } = await getPriorityFeeEstimate(_tx.serialize(), sender.publicKey.toString())
      if (!error && result?.priorityFeeLevels) {
        //Receive `priorityFee` in microLamports
        priorityFee = GasHelper.gasPay(Number(result.priorityFeeLevels[priorityLevel] || 0))
      }
    }

    const computePriceIx = ComputeBudgetProgram.setComputeUnitPrice({
      microLamports: priorityFee
    });

    /**
     * Simulate transaction for receive ComputeUnit
     */
    const {blockhash} = await getLatestBlockhash("finalized")
    const _txForSimulate = new BatchTransaction({
      recentBlockhash: blockhash
    })
    if (priorityFee) {
      _txForSimulate.add(computePriceIx)
    }
    _txForSimulate.add(
      computeLimitIx,
      txInstruction
    )
    _txForSimulate.sign(sender)

    //this approach is better than use ComputeUnitsDefault, but does not work stable
    const testVersionedTxn = new VersionedTransaction(_txForSimulate.compileMessage());
    const simulation = await simulateTransaction(testVersionedTxn);
    if (!simulation.value.err) {
      const responseData = simulation.value as SimResp
      unitLimit = Number(GasHelper.gasPay(responseData.unitsConsumed)) // add 20% of amount just in case
      computeLimitIx = ComputeBudgetProgram.setComputeUnitLimit({
        units: unitLimit
      });
    }

    /**
     * Estimate fee
     */
    const {blockhash: recentBlockhash} = await getLatestBlockhash("finalized")
    const _txForEstimate = new BatchTransaction({
      recentBlockhash: recentBlockhash
    })
    if (priorityFee) {
      _txForEstimate.add(computePriceIx)
    }
    _txForEstimate.add(
      computeLimitIx,
      txInstruction
    )
    _txForEstimate.sign(sender)
    const dataEstimate = await _txForEstimate.getEstimatedFee(connection)

    /**
     * // Get the minimum balance required for rent exemption
     *
     * In Solana, some accounts must be rent-exempt, meaning they must hold a minimum balance to avoid paying rent.
     * The rent-exempt minimum depends on the account's data size and the current rent parameters.
     */
    const accountInfo = await getAccountInfo(sender.publicKey);
    const data = accountInfo?.data as Uint8Array | undefined
    //minimum lamports required in the Account to remain rent free
    const rentExemptionInLamports = await getMinimumBalanceForRentExemption(data?.length || 0);

    // Calculate total fee (base fee + (compute unit * priority fee))
    const baseFee = BigInt(dataEstimate || FeeDefault * _txForEstimate.signatures.length)
    //Convert priorityFee in microLamports to Lamports if it`s more 0
    const computeUnitFeeInLamports = priorityFee ? Math.ceil(Number(priorityFee) / MICRO_LAMPORTS_PER_LAMPORT) : 1
    const totalFeeInLamports = baseFee + BigInt(computeUnitFeeInLamports * unitLimit) + BigInt(rentExemptionInLamports);

    return {
      totalFee: totalFeeInLamports,
      computePriceIx: priorityFee ? computePriceIx : undefined,
      computeLimitIx
    }
  }

  async generateTransactions(
    data: IDataForGenerateSOLTransactions
  ): Promise<EstimateResultType> {
    const {baseCurrencyBalanceData, transactionPriority, privateKeyByAddress, receiverAddress} = data
    const txDataByAddress: IMapValueByAddress<ITxSolData> = new Map()
    const feeDataByAddress: IMapValueByAddress<bigint> = new Map()
    const {PublicKey, SystemProgram, getKeyPair} = this._web3Provider
    const _toPubKey = new PublicKey(receiverAddress)

    const {done, value} = baseCurrencyBalanceData.entries().next()
    if (done) {
      return {txDataByAddress, feeDataByAddress}
    }
    const [_address, _balance] = value
    let _payerAddress: AddressType = _address;
    if (_balance < this._feeDefaultInLamports) {
      baseCurrencyBalanceData.forEach((_balance, _address) => {
        if (_balance > this._feeDefaultInLamports) {
          _payerAddress = _address
          return
        }
      });
    }

    const signer = getKeyPair(privateKeyByAddress.get(_payerAddress)!)

    const {totalFee} = await this._getPriorityFeeEstimate(
      SystemProgram.transfer({
        fromPubkey: signer.publicKey,
        toPubkey: _toPubKey,
        lamports: 0,
      }),
      signer,
      transactionPriority
    )

    baseCurrencyBalanceData.forEach((balanceSender, addressSender) => {

      if (addressSender.toLowerCase() === receiverAddress?.toLowerCase()) {
        return
      }

      const _fromPubKey = new PublicKey(addressSender)
      const tx = {
        fromPubkey: _fromPubKey,
        toPubkey: _toPubKey,
        lamports: balanceSender - totalFee,
        isSigner: true,
      } as TransferParams

      txDataByAddress.set(addressSender, tx)
      feeDataByAddress.set(addressSender, totalFee)
    })

    return {txDataByAddress, feeDataByAddress}
  }

  async sendTransactions(data: IDataForSendTransactions): Promise<IMapValueByAddress> {
    const {
      privateKeyByAddress, baseCurrencyBalanceData,
      transactionPriority, transactionDataByAddress
    } = data as IDataForSendSOLTransactions

    const resultTxReceipt: IMapValueByAddress<string> = new Map()
    if (transactionDataByAddress.size === 0) {
      return resultTxReceipt
    }

    const {
      getLatestBlockhash, BatchTransaction, BatchRequest,
      sendRawTransaction, SystemProgram, getKeyPair
    } = this._web3Provider


    const signedTxByAddress = new BatchRequest()

    //getPriorityFee by first tx
    const [_addressSender, _tx] = transactionDataByAddress.entries().next().value;
    const signer = getKeyPair(privateKeyByAddress.get(_addressSender)!)
    const {
      totalFee,
      computeLimitIx,
      computePriceIx
    } = await this._getPriorityFeeEstimate(
      SystemProgram.transfer(_tx),
      signer,
      transactionPriority
    )

    for (const address of transactionDataByAddress.keys()) {
      const {blockhash} = await getLatestBlockhash("finalized")
      const txParams = transactionDataByAddress.get(address)
      const amountToSend = (baseCurrencyBalanceData.get(address) || BigInt(0)) - totalFee
      if (amountToSend <= 0) {
        continue
      }

      const signer = getKeyPair(privateKeyByAddress.get(address)!)
      let transaction = new BatchTransaction({
        recentBlockhash: blockhash,
      });

      txParams.lamports = amountToSend

      if (computePriceIx) {
        transaction.add(
          computePriceIx,
        )
      }

      transaction.add(
        computeLimitIx,
        SystemProgram.transfer(txParams)
      )

      transaction.sign(signer)

      signedTxByAddress.add(sendRawTransaction.request(transaction.serialize(), address));
    }

    const dataBatchTx = await signedTxByAddress.execute({timeout: 30000})
    for (let txResult of dataBatchTx) {
      if (txResult.error) {
        const errorData = txResult.error as JsonRpcError
        throw new Error(`${errorData.message} [${errorData.code}]`)
      }
      let itemSuccess = txResult as IRpcResponse
      if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
        itemSuccess.result += '?cluster=devnet'
      }
      resultTxReceipt.set(itemSuccess.id, itemSuccess.result as HexStr)
    }
    return resultTxReceipt
  }


  validateAddress(address: AddressType): boolean {
    try {
      return this._web3Provider.isAddress(address)
    } catch (e) {
      return false
    }
  }

  privateKeyToAccount(privateKey: PrivateKeyType): IAccount {
    return this._web3Provider.privateKeyToAccount(privateKey)
  }

  toUnitFromBaseCurrency(amount: string): bigint {
    return BigInt(this._web3Provider.sol_to_lamport(Number(amount)))
  }

  toBaseCurrencyFromUnit(amount: bigint): string {
    const amountNum: number = this._web3Provider.lamport_to_sol(Number(amount))
    return amountNum.toFixed(getPrecisionByNumber(amountNum))
  }

  resetGasPriceAndNonce() {
  }
}

export {SOLFacade}
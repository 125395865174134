import {IWeb3Facade} from "../IWeb3Facade";
import {
  fromWei,
  GAS_FOR_SEND_ETHEREUM,
  HexStr,
  isAddress,
  isHexStrict,
  setProviderWeb3,
  toBigInt,
  toHex,
  toWei,
  Web3InitiatedType
} from "../../../../store/web3/web3";
import {
  AddressHexStr,
  ApiScanResponse,
  IGasPrice,
  IGasPriceResult,
  IRpcResponse,
  ITxBeforeEstimateGas,
  ITxTokenBeforeEstimateGas
} from "../../../../models/chainScan.models";
import {ERC20Tokens} from "../../../../store/etherscan/ERC20Tokens";
import {
  AddressType, BalanceDataByAddress,
  EstimateResultType,
  IAccount,
  IDataForGenerateTransactions,
  IDataForSendTransactions,
  IGeneralTxData,
  IMapValueByAddress,
  ITokenDict,
  ITransactionPriorityEnum,
  NetworkType,
  PrivateKeyType
} from "../../types";
import {JsonRpcError} from "web3-types/src/json_rpc_types";
import {Transaction} from "web3-types";
import {ITxErc20Data} from "./ERC20Facade";
import {ERC20TestnetTokens} from "../../../../store/etherscan/ERC20TestnetTokens";
import {GasHelper} from "../../../../helpers";


interface IDataForGenerateETHTransactions extends IDataForGenerateTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

interface IDataForSendETHTransactions extends IDataForSendTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  privateKeyByAddress: IMapValueByAddress<IAccount['privateKey']>,
  transactionDataByAddress: IMapValueByAddress<ITxEthData>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

export interface ITxEthData extends IGeneralTxData {
  from: HexStr,
  to: HexStr,
  value: bigint,
  chainId: bigint,
  networkId: bigint,
  gas: bigint,
  gasPrice: bigint,
  nonce: bigint,
}

export type InitDataType = {
  defaultTransactionPriority: keyof ITransactionPriorityEnum,
  transactionPriorityOptions: ITransactionPriorityEnum,
  tokensDict: ITokenDict,
  network: NetworkType,
  linkForTxScan: string,
  web3HttpProviderLink: string,
  fetchGasPriceConf: { apikey: string; url: string },
  environment?: string,
  limitPrivateKeys?: number,
  addressesChunkSize?: number
}

const TransactionPriorityEnum: ITransactionPriorityEnum = {
  low: "low",
  medium: "medium",
  high: "high"
} as const

function adapterGasPrice(rawResult: IGasPriceResult): IGasPrice {
  return {
    low: rawResult.SafeGasPrice,
    medium: rawResult.ProposeGasPrice,
    high: rawResult.FastGasPrice
  }
}

class ETHFacade implements IWeb3Facade {
  protected static gasPrice: bigint = BigInt(0);
  protected static nonceByAddress: IMapValueByAddress<number> = new Map()

  protected readonly _web3Provider: Web3InitiatedType;
  protected readonly _linkForTxScan: string;
  protected readonly _defaultTransactionPriority: keyof ITransactionPriorityEnum;
  protected readonly _transactionPriorityOptions: ITransactionPriorityEnum;
  protected readonly _tokensDict: ITokenDict;
  protected readonly _network: NetworkType;
  protected readonly _fetchGasPriceConf: { apikey: string; url: string };
  protected readonly _environment: string;
  protected readonly limitPrivateKeys: number;
  protected readonly addressesChunkSize: number;

  constructor(initData?: InitDataType) {
    this._defaultTransactionPriority = initData?.defaultTransactionPriority || TransactionPriorityEnum.medium
    this._transactionPriorityOptions = initData?.transactionPriorityOptions || {
      [TransactionPriorityEnum.low]: "Low",
      [TransactionPriorityEnum.medium]: "Average",
      [TransactionPriorityEnum.high]: "High"
    }
    this._tokensDict = initData?.tokensDict || (process.env.REACT_APP_ENVIRONMENT === 'dev' ? ERC20TestnetTokens : ERC20Tokens)
    this._network = initData?.network || 'eth'
    this._linkForTxScan = initData?.linkForTxScan || process.env.REACT_APP_LINK_FOR_TX_ETH_SCAN

    this._web3Provider = setProviderWeb3(initData?.web3HttpProviderLink || process.env.REACT_APP_ETH_WEB3_HTTP_PROVIDER)
    this._fetchGasPriceConf = initData?.fetchGasPriceConf || {
      apikey: process.env.REACT_APP_PRIVATE_KEY_FOR_ETH_SCAN_API,
      url: process.env.REACT_APP_LINK_FOR_ETH_GAS_PRICE_API
    }
    this._environment = initData?.environment || process.env.REACT_APP_ENVIRONMENT

    this.limitPrivateKeys = initData?.limitPrivateKeys || 10000;
    this.addressesChunkSize = initData?.addressesChunkSize || 800;
  }

  get tokensDict() {
    return this._tokensDict
  }

  get network() {
    return this._network
  }

  get linkForTxScan() {
    return this._linkForTxScan
  }

  get defaultTransactionPriority() {
    return this._defaultTransactionPriority
  }

  get transactionPriorityOptions() {
    return this._transactionPriorityOptions
  }

  getLimitPrivateKeys() {
    return this.limitPrivateKeys
  }

  getAddressesChunkSize() {
    return this.addressesChunkSize
  }

  async fetchBaseCurrencyBalanceDataByAddress(addressList: Set<AddressType>): Promise<BalanceDataByAddress> {
    if (addressList.size > this.getAddressesChunkSize()) {
      throw new Error(`Address size should be lower than ${this.getAddressesChunkSize()}`);
    }

    const {
      BatchRequest,
      getBalance
    } = this._web3Provider
    const balanceByAddress: BalanceDataByAddress = new Map();

    const batchInfo = new BatchRequest();
    addressList.forEach(address => {
      batchInfo.add(getBalance.request(address as AddressHexStr));
    })

    const dataInfo = await batchInfo.execute({timeout: 30000})
    for (let item of dataInfo) {
      if (item.error) {
        const errorData = item.error as JsonRpcError
        throw new Error(errorData.message + `[${errorData.code}]`)
      }
      let itemSuccess = item as IRpcResponse

      balanceByAddress.set(itemSuccess.id, getBalance.outputFormatter(itemSuccess.result))
    }
    return balanceByAddress
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    if (ETHFacade.gasPrice === BigInt(0)) {
      const params = {
        module: 'gastracker',
        action: 'gasoracle',
        apikey: this._fetchGasPriceConf.apikey
      }
      const data: ApiScanResponse<IGasPriceResult> = await fetch(this._fetchGasPriceConf.url + '/api/?' + new URLSearchParams(params)).then(response => response.json())
      if (parseInt(data?.status || '0') === 1) {
        /**
         * In test(dev) env use  web3.eth.getGasPrice() to get actual price for testnet
         */
        if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
          const {getGasPriceInWei} = this._web3Provider
          const slowInWei: bigint = await getGasPriceInWei()
          const slowInGwei = BigInt(Math.ceil(Number(fromWei(slowInWei, 'gwei'))))
          data.result = {
            SafeGasPrice: slowInGwei.toString(),
            ProposeGasPrice: GasHelper.gasPricePlusPercent(slowInGwei, 50).toString(),
            FastGasPrice: GasHelper.gasPricePlusPercent(slowInGwei, 100).toString(),
            LastBlock: "_not_realized_",
            suggestBaseFee: "_not_realized_",
            gasUsedRatio: "_not_realized_",
          }
          console.table({...data.result})
        }
        const gasPrice = adapterGasPrice(data.result)
        ETHFacade.gasPrice = toBigInt(toWei(gasPrice[transactionPriority as keyof IGasPrice] || 0, 'Gwei'))
      }
    }

    return ETHFacade.gasPrice;
  }

  resetGasPriceAndNonce() {
    ETHFacade.gasPrice = BigInt(0)
    ETHFacade.nonceByAddress = new Map()
  }

  async __fetchNonce(addressList: Set<AddressType>) {
    const {
      BatchRequest,
      getTransactionCount,
    } = this._web3Provider

    const batchNonce = new BatchRequest();

    addressList.forEach(address => {
      batchNonce.add(getTransactionCount.request(address as AddressHexStr));
    })
    const dataNonce = await batchNonce.execute({timeout: 30000})
    for (let item of dataNonce) {
      if (item.error) {
        const errorData = item.error as JsonRpcError
        throw new Error(errorData.message + `[${errorData.code}]`)
      }
      let itemSuccess = item as IRpcResponse
      if (!ETHFacade.nonceByAddress.has(itemSuccess.id)) {
        ETHFacade.nonceByAddress.set(itemSuccess.id, getTransactionCount.outputFormatter(itemSuccess.result))
      }
    }
    return ETHFacade.nonceByAddress
  }

  async generateTransactions(
    data: IDataForGenerateETHTransactions
  ): Promise<EstimateResultType> {
    const {baseCurrencyBalanceData, transactionPriority, receiverAddress} = data
    const txDataByAddress: IMapValueByAddress<ITxEthData> = new Map()

    const gasPriceInWei = await this._fetchGasPriceInWei(transactionPriority)
    const nonceByAddress = await this.__fetchNonce(new Set(baseCurrencyBalanceData.keys()))
    const chainId = await this._web3Provider.getChainId()
    const networkId = await this._web3Provider.getNetworkId()
    const gas = BigInt(GAS_FOR_SEND_ETHEREUM)

    baseCurrencyBalanceData.forEach((balance, address) => {
      const accountHex: AddressHexStr = address as AddressHexStr
      if (accountHex.toLowerCase() === receiverAddress?.toLowerCase()) {
        return
      }

      if (balance > 0) {
        const feeWei = gas * gasPriceInWei
        const amountToSend = balance - feeWei

        txDataByAddress.set(accountHex, {
          from: accountHex,
          to: receiverAddress,
          chainId: chainId,
          networkId: BigInt(networkId),
          gasPrice: gasPriceInWei,
          gas: gas,
          value: amountToSend,
          nonce: BigInt(nonceByAddress.get(address)!),
        })
      }
    })

    return await this._estimateFee(txDataByAddress, gasPriceInWei)
  }

  async sendTransactions(data: IDataForSendETHTransactions): Promise<IMapValueByAddress> {
    const {
      privateKeyByAddress, baseCurrencyBalanceData,
      receiverAddress, transactionPriority, transactionDataByAddress
    } = data

    const resultTxReceipt: IMapValueByAddress<HexStr> = new Map()
    const signedTxByAddress = new this._web3Provider.BatchRequest()
    const gasPriceInWei = await this._fetchGasPriceInWei(transactionPriority)

    const {feeDataByAddress, txDataByAddress} = await this._estimateFee(transactionDataByAddress, gasPriceInWei)

    for (const address of txDataByAddress.keys()) {
      if (address.toLowerCase() === receiverAddress.toLowerCase() || !txDataByAddress.has(address)) {
        continue
      }

      const balance = baseCurrencyBalanceData.get(address) ?? BigInt(0)
      const txDataBeforeToHex = txDataByAddress.get(address)
      const feeWei = feeDataByAddress.get(address) ?? BigInt(0)

      if (privateKeyByAddress.has(address) && txDataBeforeToHex && (balance > feeWei)) {
        txDataBeforeToHex.gasPrice = gasPriceInWei

        //TODO temporary solution //Possible error "insufficient funds for gas * price + value: balance ..."
        if (txDataBeforeToHex.value !== BigInt(0)) {
          console.log('many')
          //update amount to send because of new gas price
          txDataBeforeToHex.value = balance - feeWei
        }
        console.log('-----start-----')
        console.table({
          gasPriceInWei,
          feeWei,
          balance,
        })
        console.table({...txDataBeforeToHex})
        console.log('-----end-----')

        const txDataHex: Transaction = this._toHexTxData(txDataBeforeToHex)

        const {rawTransaction} = await this._web3Provider.signTransaction(txDataHex, privateKeyByAddress.get(address)!)
        signedTxByAddress.add(this._web3Provider.sendSignedTransaction.request(rawTransaction, address as AddressHexStr))
      }
    }
    const dataBatchTx = await signedTxByAddress.execute({timeout: 30000})
    for (let txResult of dataBatchTx) {
      if (txResult.error) {
        const errorData = txResult.error as JsonRpcError
        throw new Error(`${errorData.message} [${errorData.code}]`)
      }
      let itemSuccess = txResult as IRpcResponse
      resultTxReceipt.set(itemSuccess.id, itemSuccess.result as HexStr)
    }
    return resultTxReceipt
  }

  validateAddress(address: AddressType): boolean {
    return isHexStrict(address) && isAddress(address)
  }

  privateKeyToAccount(privateKey: PrivateKeyType): IAccount {
    if (!isHexStrict(privateKey)) {
      privateKey = `0x${privateKey}`
    }

    return this._web3Provider.privateKeyToAccount(privateKey)
  }

  toUnitFromBaseCurrency(amount: string): bigint {
    return BigInt(toWei(amount, 'ether'))
  }

  toBaseCurrencyFromUnit(amount: bigint): string {
    const amountBase: string = fromWei(amount, 'ether')
    if (amountBase[amountBase.length - 1] === '.') {
      return amountBase.substring(0, amountBase.length - 1)
    }
    return amountBase
  }

  protected async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<ITxEthData>, gasPriceInWei: bigint) {
    const feeDataByAddress: IMapValueByAddress<bigint> = new Map()
    const gas = BigInt(GAS_FOR_SEND_ETHEREUM)
    const feeWei = gas * gasPriceInWei
    console.log('eth (L2)', feeWei)
    for (const address of txDataForEstimateByAddress.keys()) {
      feeDataByAddress.set(address, feeWei)
    }

    return {txDataByAddress: txDataForEstimateByAddress, feeDataByAddress}
  }

  protected _toHexTxData(txData: ITxEthData | ITxErc20Data): ITxBeforeEstimateGas | ITxTokenBeforeEstimateGas {
    return {
      ...txData,
      gasPrice: toHex(txData.gasPrice),
      chainId: toHex(txData.chainId),
      networkId: toHex(txData.networkId),
      gas: toHex(txData.gas),
      value: toHex(txData.value),
      nonce: toHex(txData.nonce),
    }
  }
}

export {ETHFacade}